import React from 'react';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import { green, red, yellow, lime, orange, grey } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography, useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';
import { makeStyles } from '@material-ui/core/styles';
import label from 'src/utils/label';
import { labels } from 'src/labels';
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    label: {
        alignItems: 'left',
        justifyContent: 'left',
        flexGrow: 0,
        flexShrink: 0,
        lineHeight: '15px',
        fontSize: '9px'
    },
    npsLegend: {
        alignItems: 'baseline'
    },
    rightmargin: { marginRight: '5px' }
}));

const GreenToggleButton10 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: green[800],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: green[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    },

})((props) => <ToggleButton {...props} />);

const GreenToggleButton9 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: green[600],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: green[400]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton8 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[600],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: lime[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const LimeToggleButton7 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: lime[400],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: lime[300]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const YellowToggleButton6 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: yellow[600],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: yellow[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const YellowToggleButton5 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: yellow[800],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: yellow[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const OrangeToggleButton4 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: orange[600],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: orange[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const OrangeToggleButton3 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: orange[800],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: orange[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton2 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[400],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: red[300]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton1 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[600],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: red[500]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedToggleButton0 = withStyles({
    root: {
        color: "white",
        border: 0,
        background: red[800],
        maxWidth: '30px',
        "&:hover": {
            backgroundColor: red[700]
        },
        "&.MuiToggleButton-root.Mui-selected": {
            color: "black",
            background: grey[100],
            fontWeight: "bold"
        }
    }
})((props) => <ToggleButton {...props} />);

const RedSentimentVeryDissatisfiedIcon = withStyles({
    root: {
        color: red[600],
        fontSize: 25
    }
})((props) => <SentimentVeryDissatisfiedIcon {...props} />);

const GreenSentimentVerySatisfiedIcon = withStyles({
    root: {
        color: green[600],
        fontSize: 25
    }
})((props) => <SentimentVerySatisfiedIcon {...props} />);

const YellowSentimentSatisfiedIcon = withStyles({
    root: {
        color: yellow[600],
        fontSize: 25
    }
})((props) => <SentimentSatisfiedIcon {...props} />);

const NPS = ({ ro, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const [value, setNPSValue] = React.useState("");
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    let changeSize = localStorage.getItem('lang') === 'ar' && mobileDevice ? '14px' : '12px'
    const handleNPSChange = (event, newValue) => {
        setNPSValue(newValue);
        handleChange(event, newValue, questionId);
    };
    let sortedAnswers = answers.sort((a, b) => (a.score < b.score) ? 1 : -1);
    const answersList = sortedAnswers.map((answer) => {
        var x = label(answer.label);
        var result;
        switch (x) {
            case '0':
                result = <RedToggleButton0 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton0>;
                break;
            case '1':
                result = <RedToggleButton1 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton1>;
                break;
            case '2':
                result = <RedToggleButton2 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</RedToggleButton2>;
                break;
            case '3':
                result = <OrangeToggleButton3 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton3>;
                break;
            case '4':
                result = <OrangeToggleButton4 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</OrangeToggleButton4>;
                break;
            case '5':
                result = <YellowToggleButton5 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</YellowToggleButton5>;
                break;
            case '6':
                result = <YellowToggleButton6 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</YellowToggleButton6>;
                break;
            case '7':
                result = <LimeToggleButton7 className={classes.rightmargin} disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton7>;
                break;
            case '8':
                result = <LimeToggleButton8 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</LimeToggleButton8>;
                break;
            case '9':
                result = <GreenToggleButton9 className={classes.rightmargin} disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton9>;
                break;
            case '10':
                result = <GreenToggleButton10 disabled={JSON.parse(ro)} selected={isChecked(questionId, answer.id)} key={answer.id} value={questionId + '_' + answer.id}>{x}</GreenToggleButton10>;
                break;
            default:
                result = null;
                break;
        }
        return (result)
    });
    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ToggleButtonGroup size={mobileDevice ? 'small' : 'large'} value={value} exclusive onChange={handleNPSChange}>
                            {answersList}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.npsLegend} maxWidth={mobileDevice ? '400px' : '320px'} display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                            <Box width='65px' display="flex" flexDirection="column">
                                <GreenSentimentVerySatisfiedIcon />
                                <Typography style={{paddingTop: "5px", fontSize: changeSize}} className={classes.label}>
                                    {(questionId === 1069) ? label(labels.get("recommend")) : label(labels.get("extremely_likely"))}
                                </Typography>
                            </Box>
                            <Box width="40px" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <YellowSentimentSatisfiedIcon />
                                <Typography style={{paddingTop: "5px", fontSize: changeSize}} className={classes.label}>
                                    {label(labels.get("neutral"))}
                                </Typography>
                            </Box>
                            <Box width="210px" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                                <RedSentimentVeryDissatisfiedIcon />
                                <Typography style={{paddingTop: "5px", fontSize: changeSize}} className={classes.label}>
                                    {(questionId === 1069) ? label(labels.get("not_recommend")) : label(labels.get("not_at_all_likely"))}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default NPS;