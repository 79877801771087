import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, Typography, LinearProgress } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import content from 'src/utils/ContentUtil';
import Question from './Question';
import Matrix from './Matrix';
import Box from '@material-ui/core/Box'
import { green } from "@material-ui/core/colors";
import Alert from '@material-ui/lab/Alert';
import { labels } from 'src/labels';
import label from 'src/utils/label';
import { useMediaQuery } from "@material-ui/core";
import theme from 'src/theme';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& .MuiMobileStepper-progress': {
      backgroundColor: green[200]
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: green[600]
    },
  },
  stepper: {
    backgroundColor: '#F4F6F8'
  }
});

function LinearProgressWithLabel(props) {
  return (
    <Box width='100%' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box style={{ width: '100%', marginRight: theme.spacing(1) }}>
        <LinearProgress variant="determinate" color="primary" {...props} />
      </Box>
      <Box style={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function ProgressMobileStepper({ ro, handleMatrixNext, varMap, handleBack, activeStep, isValid, loading, handleNext, matrixSurvey, getComments, isChecked, handleChange, questions, answers, translateX, opacity, logics }) {
  const classes = useStyles();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  let changeSize = localStorage.getItem('lang') === 'ar' && mobileDevice ? '15px' : '13px'
  let matrix = {};
  matrix.questions = [];
  const styles ={
    transition: 'all 0.5s ease-in-out'
  }

  if(!!questions[activeStep].group) {
    matrix = questions[activeStep].group;
    matrix.questions = questions.filter(q => q.group && q.group.id === matrix.id)
    matrix.questions.sort((a, b) => (a.groupStepNo > b.groupStepNo) ? 1 : -1);
  }

  const matrixNext = () => {
    handleMatrixNext(matrix)
  };

  return (
    <Box width="100%" justifyItems="center" style = {{...styles, transform: `translate(${translateX}%, 0%)`, opacity: opacity}}>
      {!isValid && <Box p={2}><Alert variant="outlined" severity="error">
        {!matrix.id ? label(labels.get("please_answer_this_question")) : label(labels.get("please_answer_the_questions"))}
      </Alert> </Box>}
      {!matrix.id && <Question ro={ro} getComments={getComments} isChecked={isChecked} handleChange={handleChange} question={questions[activeStep]} answers={answers} questions={questions} varMap={varMap} />}
      {!!matrix.id && matrix.questions.length > 0 && <Matrix ro={ro} getComments={getComments} isChecked={isChecked} handleChange={handleChange} question={questions[activeStep]} answers={answers} questions={matrix.questions} logics={logics} />}
      {/* {activeStep === questions.length - 1 && <Followup />} */}
      {activeStep < questions.length - 1 ?
        <Box className={classes.stepper} width="100%" justifyItems="center" display='flex' flexDirection='row' justifyContent='space-between'>
          <Box m={1}>
            {activeStep > 0 ?
            <Button style={{ fontSize: changeSize }} size="small" onClick={handleBack} disabled={activeStep === 0}>
              {content(<KeyboardArrowLeft />, <KeyboardArrowRight />)}
              {label(labels.get("back"))}
            </Button> : null}
          </Box>
          <LinearProgressWithLabel value={Math.floor((activeStep / ((questions.length -1))) * 100)} />
          <Box m={1} display='flex' justifyContent='flexEnd'>
          <Button style={{ fontSize: changeSize }} size="small" onClick={matrixSurvey ? matrixNext : handleNext} disabled={loading && activeStep === questions.length - 1}>                
            {loading && <Box pr={1}><CircularProgress color='inherit' size={14} /></Box> }
            {label(labels.get("next"))}
            {content(<KeyboardArrowRight />, <KeyboardArrowLeft />)}
          </Button>
          </Box>
        </Box>
        : null}
    </Box>
  );
}