import React, { useEffect, useState} from 'react';
import {
  Card,
  Grid,
  makeStyles,
  CardContent,
  TextField,
  Button,
  Modal,
  Box
} from '@material-ui/core';
import { Form, Formik } from "formik";
import { labels } from 'src/labels';
import label from 'src/utils/label';
import CustomizedSnackbar from 'src/components/CustomizedSnackbar';
const _actions = [
    {
        "code": "CASE_UNASSIGNED",
        "desc": "Case unassigned"
    },
    {
        "code": "CASE_AWAITING_ACTION",
        "desc": "Case awaiting action"
    },
    {
        "code": "CASE_COMPLETE",
        "desc": "Case completed"
    },
    {
        "code": "CALL_BACK_ARRANGED",
        "desc": "Call back arranged"
    }
];
const _agents = [
    {
        "userId": 10,
        "name": "Habeeb VS"
    },
    {
        "userId": 3,
        "name": "Syed Faheema Abdul Salam"
    },
    {
        "userId": 11,
        "name": "VS Agent"
    },
    {
        "userId": 20,
        "name": "Khalid Al Mijmaj"
    },
    {
        "userId": 35,
        "name": "Aktar Abdullah"
    },
    {
        "userId": 36,
        "name": "Ahmed M.Alotaibi"
    },
    {
        "userId": 37,
        "name": "Tharique Afridi"
    },
    {
        "userId": 9,
        "name": "Musthaq Ahamed"
    },
    {
        "userId": 4,
        "name": "Mounica Chandra"
    }
];
const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        padding: 0,
        '&:last-child': {
        paddingBottom: 0
        }
    },
    details: {
        padding: theme.spacing(1, 3)
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: theme.spacing(4, 3)
    },
    button: {
        margin: theme.spacing(1)
    },
    comments: {
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
    },
    '.MuiSelect-select': {
        display:'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
}
}));

const CaseModal = ({ token, open, handleClose, caseAction, agentUserId, agentRole, ...props }) => {
    const classes = useStyles();
    const formData = {token: token, caseAction : '-1', assigneeUserId: '-1', caseComments: '', agentUserId: Number(agentUserId)}
    
    const [actionOptions, setActionOptions] = React.useState(_actions);
    const [agentOptions, setAgentOptions] = React.useState(_agents);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarSeverity, setSnackBarSeverity] = useState('success');
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const snackBarAutoHideDuration = 2000;

    const handleSnackBarClose = () => {
        setSnackBarOpen(false);
    };

    useEffect(() => {
        setAgentOptions(_agents);
        setActionOptions(_actions);
  }, [])

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Card className={classes.modal} >
        <CardContent className={classes.content}>
            <div className={classes.details}>
            <Formik
                enableReinitialize={true}
                initialValues = {formData}
                onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                    let data = {};
                    data.token = values.token;
                    data.agentUserId = values.agentUserId;
                    if(values.caseAction !== '-1' || values.caseComments.trim().length > 0 || values.assigneeUserId !== '-1') {
                        if(values.caseAction !== '-1') {
                            data.caseAction = values.caseAction
                        }
                        if(values.caseComments.trim().length > 0 ) {
                            data.caseComments = values.caseComments.trim()
                        }
                        if(values.assigneeUserId !== '-1'){
                            data.assigneeUserId = Number(values.assigneeUserId)
                        }
                    }
                    setSnackBarSeverity('info');
                    setSnackBarMessage('Loading. Please wait...')
                    // setSnackBarOpen(true);
                    // axios.post(API_URL + "/api/voc/case-actions", data)
                    //     .then(response => {
                    //         if (response.data) {
                    //             setSubmitting(false);
                    //             setSnackBarSeverity('success');
                    //             setSnackBarMessage('Successfully saved')
                    //             //setSnackBarOpen(false);
                    //             console.log("Successfully saved case actions")
                    //         }
                    //     }).catch(error => {
                    //         setSubmitting(false);
                    //         setSnackBarSeverity('error');
                    //         setSnackBarMessage('Failed to save!')
                    //         setSnackBarOpen(false);
                    //         console.log(JSON.stringify(error));
                    //     });
                }}
            >
                {({ values, touched, errors, status, handleChange, handleBlur, isValid, isSubmitting, dirty }) => (
                <Form noValidate autoComplete="off">
                    <Grid
                    alignItems="center"
                    container
                    spacing={4}
                    >
                    <Grid item md={12} xs={12}>
                        <TextField
                            fullWidth
                            label={label(labels.get("add_new_action"))}
                            name="caseAction"
                            onChange={handleChange}
                            select
                            SelectProps={{ native: true }}
                            value={values.caseAction}
                            variant="outlined"
                        >
                            <option value="-1">{label(labels.get("select"))}</option>
                            {!!actionOptions && actionOptions.map((a, index) => {
                                console.log(agentRole)
                                if(agentRole && agentRole !== 'AGENT_MANAGER' && a.code === 'CASE_UNASSIGNED') return '';
                                return (
                                <option
                                    key={index}
                                    value={a.code}
                                >
                                    {a.desc}
                                </option>)
                            })}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                        fullWidth
                        variant="outlined"
                        label={label(labels.get("reassign"))}
                        name="assigneeUserId"
                        select
                        onChange={handleChange}
                        SelectProps={{ native: true }}
                        value={values.assigneeUserId}
                        >
                            <option value="-1">{label(labels.get("select"))}</option>
                            {!!agentOptions && agentOptions.map((a, index) => (
                            <option
                                key={index}
                                value={a.userId}
                            >
                                {a.name}
                            </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            multiline fullWidth rows={2}
                            variant="outlined"
                            label={label(labels.get("comments"))}
                            name="caseComments"
                            value={values.caseComments}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Button
                            className={classes.button}
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={((values.caseComments.trim().length === 0 && values.assigneeUserId === '-1' && values.caseAction === '-1' ) || isSubmitting)}
                        >
                            {label(labels.get("save"))}
                        </Button>
                        <Button
                            className={classes.button}
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleClose}
                            disabled={isSubmitting}
                        >
                            {label(labels.get("cancel"))}
                        </Button>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                            <CustomizedSnackbar
                                open={snackBarOpen}
                                handleClose={handleSnackBarClose}
                                severity={snackBarSeverity}
                                msg={snackBarMessage}
                                autoHideDuration={snackBarAutoHideDuration}
                            />
                        </Box>
                    </Grid>
                    </Grid>
                </Form>
                )
                }
                </Formik >
            </div>
        </CardContent>
        </Card>
        </Modal>
    );
};

export default CaseModal;