import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import label from 'src/utils/label';
import Answers from './Answers';
import { Divider, useMediaQuery, Box } from "@material-ui/core";
import theme from 'src/theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "white"
        }
    }
}));

const Matrix = ({ ro, getComments, isChecked, handleChange, logics, questions, answers }) => {
    const classes = useStyles();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
    let changeSize = localStorage.getItem('lang') === 'ar' && mobileDevice ? '17px' : '14px'
    let align = null;
    const [showQuestions, setShowQuestions] = React.useState([])

    useEffect(() => {
        setShowQuestions(questions.map(q => q.id))
    }, [questions])

    //show questions logic
    if((!! logics && !!logics.filters) || (!! logics &&  !!logics.displays)) {
        let sourceFilterQuestions = logics.filters && logics.filters.length > 0 ? questions.filter(q => logics.filters.map(ff => ff.sourceQuestionId).includes(q.id)).map(qq => qq.id) : [];
        let sourceDisplayQuestions = logics.displays && logics.displays.length > 0 ? questions.filter(q => logics.displays.map(ff => ff.sourceQuestionId).includes(q.id)).map(qq => qq.id) : [];
        let filterFound = answers.filter(a => sourceFilterQuestions.includes(a.questionId))
        let displayFound = answers.filter(a => sourceDisplayQuestions.includes(a.questionId))
        let destinationMatrixDisplayQuestions = logics.displays && logics.displays.length > 0 ? questions.filter(q => logics.displays.map(ff => ff.questionId).includes(q.id)) : [];
        if(filterFound && filterFound.length > 0){
            filterFound.forEach(f => {
                let _showQuestionId = logics.filters.find(ff => ff.sourceQuestionId === f.questionId).questionId
                let index = showQuestions.findIndex(fq => fq === _showQuestionId)
                if(index < 0) {
                    showQuestions.push(_showQuestionId)
                }
            })
        }
        if(displayFound && displayFound.length > 0) {
            displayFound.forEach(df => {
                let _showQuestionId = logics.displays.find(ff => ff.sourceQuestionId === df.questionId).questionId
                let _answers = logics.displays.find(ff => ff.sourceQuestionId === df.questionId).sourceAnswerIds
                let index = showQuestions.findIndex(fq => fq === _showQuestionId)
                if(index < 0 && _answers.includes(df.answerId)) {
                    let sourceStepNo = questions.find(q => q.id === df.questionId).groupStepNo;
                    let destinationStepNo= questions.find(q => q.id === _showQuestionId).groupStepNo;
                    let hideQuestions = questions.filter(q => q.groupStepNo > sourceStepNo && q.groupStepNo < destinationStepNo).map(qq => qq.id);
                    showQuestions.push(_showQuestionId)
                    let _ss = showQuestions.filter(sq => !hideQuestions.includes(sq))
                    _ss.forEach(sq => {
                        const index = showQuestions.indexOf(sq);
                        if(index < 0) showQuestions.push(sq);
                    })
                    let result = showQuestions.map(sq => hideQuestions.includes(sq));
                    let isHideQuestions = result.some((item) => item === true)
                    if(isHideQuestions) {
                        hideQuestions.forEach(hq => {
                            const index = showQuestions.indexOf(hq);
                            if(index > 0) showQuestions.splice(index, 1);
                        })
                    }
                } else {
                    if(destinationMatrixDisplayQuestions && destinationMatrixDisplayQuestions.length > 0) {
                        let _hidden = [];
                        destinationMatrixDisplayQuestions.forEach( dq => {
                            let sourceQuestionId = dq.displayConfig.sourceQuestionId;
                            let sourceAnswerIds = dq.displayConfig.sourceAnswerIds;
                            let isMatch = false;
                            let destinationStepNo = dq.groupStepNo;
                            let sourceStepNo = questions.find(q => q.id === dq.displayConfig.sourceQuestionId).groupStepNo;
                            let hideQuestions = questions.filter(q => q.groupStepNo > sourceStepNo && q.groupStepNo < destinationStepNo).map(qq => qq.id);
                            if(hideQuestions && hideQuestions.length === 0){
                                hideQuestions = questions.filter(q => q.groupStepNo > sourceStepNo && q.groupStepNo <= destinationStepNo).map(qq => qq.id)
                                hideQuestions.forEach(hq => {
                                    const index = showQuestions.indexOf(hq);
                                    if(index >= 0) showQuestions.splice(index, 1);
                                })
                                _hidden.push(dq.id)
                            } 
                            sourceAnswerIds.forEach(function (sourceAnswerId) {
                                isMatch = answers.some(item => item.questionId === sourceQuestionId && item.answerId === parseInt(sourceAnswerId));
                                if(isMatch) {
                                    hideQuestions.forEach(hq => {
                                        const index = showQuestions.indexOf(hq);
                                        if(index >= 0) showQuestions.splice(index, 1);
                                    })
                                    if(_hidden.includes(dq.id)) {
                                        const index = _hidden.indexOf(dq.id);
                                        if(index >= 0) _hidden.splice(index, 1);
                                    }
                                } else {
                                    hideQuestions.forEach(hq => {
                                        const index = showQuestions.indexOf(hq);
                                        const _hiddenIndex = _hidden.indexOf(hq);
                                        let removeIndex = showQuestions.indexOf(dq.id)
                                        if(removeIndex >= 0) showQuestions.splice(removeIndex, 1);
                                        if(index < 0 && _hiddenIndex < 0) {
                                            showQuestions.push(hq);
                                        }
                                    })
                                }
                            });
                        })
                    }
                }
            })
        }
    }
    let _questions  = showQuestions && showQuestions.length > 0 ? questions.filter(qq =>  showQuestions.includes(qq.id)) : questions
    return (
        _questions.map((question, index) => {
            const toggleAcordion = () => {
                question.expand = !question.expand
            };
            if(question.type === 'SMILEY' || question.type === 'STAR') {
                align = 'center'
            }
            let answersFiltered = question.answers;
            let filterConfig = question.filterConfig;
            if (!!filterConfig) {
                let selectedAnswerIds = answers.filter(item => item.questionId === filterConfig.sourceQuestionId).map(s => s.answerId);
                let selectedOtherAnswer = answers.find(item => item.questionId === filterConfig.sourceQuestionId  && !!question.comments && item.comments !== "");
                let selectedQuestion = questions.find(q => q.id === filterConfig.sourceQuestionId);
                let selectedAnswerLabels = selectedQuestion.answers.filter(item => selectedAnswerIds.includes(item.id)).map(item => item.label.en);
                answersFiltered = answersFiltered.filter(item => selectedAnswerLabels.includes(item.label.en));
                if (selectedOtherAnswer) {
                    answersFiltered.push({ "id": selectedOtherAnswer.answerId, "questionId": question.id, "score": 0, "comments": false, "label": { "ar": selectedOtherAnswer.comments, "en": selectedOtherAnswer.comments } })
                }
                if(answersFiltered && answersFiltered.length === 0){
                    let _filterQuestions = showQuestions.filter(fq => fq !== question.id)
                    setShowQuestions(_filterQuestions);
                }
            }
            let displayLogic = question.displayConfig;
            if (!!displayLogic) {
                let sourceQuestionId = displayLogic.sourceQuestionId;
                let destinationStepNo = question.groupStepNo;
                let sourceStepNo = questions.find(q => q.id === sourceQuestionId).groupStepNo;
                let hideQuestions = questions.filter(q => q.groupStepNo > sourceStepNo && q.groupStepNo <= destinationStepNo).map(qq => qq.id);
                let sourceAnswerIds = displayLogic.sourceAnswerIds;
                let isMatch = false;
                sourceAnswerIds.forEach(function (sourceAnswerId) {
                    if (isMatch) return;
                    isMatch = answers.some(item => item.questionId === sourceQuestionId && item.answerId === parseInt(sourceAnswerId));
                });
                if (!isMatch) {
                    let _showQuestions = showQuestions.filter(fq => !hideQuestions.includes(fq))
                    setShowQuestions(_showQuestions);
                }
            }
            return (
                <Box p={2} key={index}>
                    {index === 0 && <Typography variant="subtitle2" style={{fontSize: changeSize, whiteSpace: 'pre-line'}}>
                        {label(question.group.label)}
                    </Typography>}
                    <Accordion elevation={0}  classes={{root: classes.MuiAccordionroot}} key={index} tabIndex={index} defaultExpanded={index === 0 ? true : false} expanded={question.expand} style = {{boxShadow : 'none' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            IconButtonProps={{
                                onClick: toggleAcordion
                            }}
                            >
                            <Typography variant="subtitle2" style={{fontSize: changeSize, whiteSpace: 'pre-line'}}>
                                {label(question.label)}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{justifyContent:align}}>
                            {question.answers
                                && question.answers.length > 0
                                && <Answers ro={ro} isComments={question.comments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} type={question.type} questionId={question.id} answers={answersFiltered} />}
                            </AccordionDetails>
                        {(index < questions.length-1) && <Divider />}
                    </Accordion>
                </Box>
            )
        })
    );
}
export default Matrix;