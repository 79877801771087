import React from 'react';
import Radios from './Radios';
import Checkboxes from './CheckBoxes';
import NPS from './NPS';
import Smileys from './Smileys';
import Box from '@material-ui/core/Box';
import Dropdown from './Dropdown';
import Star from './Star'


const Answers = ({ ro, isComments, getComments, isChecked, handleChange, type, questionId, answers }) => {

    const renderAnswers = () => {
        switch (type) {
            case 'RADIO':
                return <Radios ro={ro} isComments={isComments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'DROPDOWN':
                return <Dropdown ro={ro} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'CHECKBOX':
                return <Checkboxes ro={ro} isComments={isComments} getComments={getComments} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'NPS':
                return <NPS ro={ro} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'SMILEY':
                return <Smileys ro={ro} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            case 'STAR':
                return <Star ro={ro} isChecked={isChecked} handleChange={handleChange} key={type} questionId={questionId} answers={answers} />;
            default:
                return null;
        }
    }

    return (
        <Box>{renderAnswers()}</Box>
    );
}
export default Answers;