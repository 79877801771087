import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import label from 'src/utils/label';
import TextArea from './TextArea';

const useStyles = makeStyles({
    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
    }
});

const GreenRadio = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        },
    },
    checked: {}
})((props) => <Radio color="default" {...props} />);

const Radios = ({ ro, isComments, getComments, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    const answersList = answers.map((answer) => {
        return (
            <FormControlLabel
                key={answer.id}
                value={questionId + '_' + answer.id}
                control={<GreenRadio size="small" />}
                label={label(answer.label)}
                labelPlacement="end"
                onChange={handleChange}
                checked={isChecked(questionId, answer.id)}
            />
        )
    });

    const _answersList = answers.map((answer, index) => {
        if (isComments) {
          if (index < (answers.length)) {
            return (
              <Box key={answer.id} display={'flex'} flexDirection={'column'}>
              <FormControlLabel
                key={answer.id}
                value={questionId + '_' + answer.id}
                control={<GreenRadio size="small" />}
                label={label(answer.label)}
                labelPlacement="end"
                onChange={handleChange}
                checked={isChecked(questionId, answer.id)}
            />
              {index === answers.length - 1 && isChecked(questionId, answer.id) && <TextArea ro={ro} getComments={getComments} key={answer.id} handleChange={handleChange} questionId={questionId} answer={answer} />}
              </Box>
            )
          }
        } else {
          return (
            <FormControlLabel
                key={answer.id}
                value={questionId + '_' + answer.id}
                control={<GreenRadio size="small" />}
                label={label(answer.label)}
                labelPlacement="end"
                onChange={handleChange}
                checked={isChecked(questionId, answer.id)}
            />
          )
        }
        return null;
      });

    return (
        <Box display="flex" alignItems="left" justifyContent="left" flexDirection="column">
            <FormControl disabled={JSON.parse(ro)} component="fieldset">
                <RadioGroup className={classes.group} aria-label={'q' + questionId} name={'q' + questionId} >
                    { [1025, 1026, 1030, 1032, 1033, 1034, 1037, 1038, 1039, 1041, 1044, 1047, 1050, 1055, 1057, 1059, 1067, 1068].includes(questionId) ? _answersList : answersList}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default Radios;